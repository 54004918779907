/**
 * Font Declarations
 * Supporting languages: English, Arabic, Spanish, Portuguese
 * Weights: 100 (Thin), 200 (ExtraLight), 300 (Light), 400 (Regular), 
 *         500 (Medium), 600 (SemiBold), 700 (Bold), 800 (ExtraBold), 900 (Black)
 */

// Roboto - Latin script (en, es, pt)
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-VariableFont_wdth,wght.ttf') format('truetype-variations'),
       url('../assets/fonts/Roboto/static/Roboto-Regular.ttf') format('truetype');
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Italic-VariableFont_wdth,wght.ttf') format('truetype-variations'),
       url('../assets/fonts/Roboto/static/Roboto-Italic.ttf') format('truetype');
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: italic;
  font-display: swap;
}

// Static Roboto fonts as fallback
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/static/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

// Noto Sans - Latin script (en, es, pt)
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/NotoSans-VariableFont_wdth,wght.ttf') format('truetype-variations'),
       url('../assets/fonts/Noto_Sans/static/NotoSans-Regular.ttf') format('truetype');
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/NotoSans-Italic-VariableFont_wdth,wght.ttf') format('truetype-variations'),
       url('../assets/fonts/Noto_Sans/static/NotoSans-Italic.ttf') format('truetype');
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: italic;
  font-display: swap;
}

// Static Noto Sans fonts as fallback
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/Noto_Sans/static/NotoSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

// Noto Sans Arabic - Arabic script (ar)
@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('../assets/fonts/Noto_Sans_Arabic/NotoSansArabic-VariableFont_wdth,wght.ttf') format('truetype-variations'),
       url('../assets/fonts/Noto_Sans_Arabic/static/NotoSansArabic-Regular.ttf') format('truetype');
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: normal;
  font-display: swap;
}

// Static Noto Sans Arabic fonts as fallback
@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('../assets/fonts/Noto_Sans_Arabic/static/NotoSansArabic-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('../assets/fonts/Noto_Sans_Arabic/static/NotoSansArabic-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('../assets/fonts/Noto_Sans_Arabic/static/NotoSansArabic-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('../assets/fonts/Noto_Sans_Arabic/static/NotoSansArabic-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('../assets/fonts/Noto_Sans_Arabic/static/NotoSansArabic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('../assets/fonts/Noto_Sans_Arabic/static/NotoSansArabic-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('../assets/fonts/Noto_Sans_Arabic/static/NotoSansArabic-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('../assets/fonts/Noto_Sans_Arabic/static/NotoSansArabic-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('../assets/fonts/Noto_Sans_Arabic/static/NotoSansArabic-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

// Font family variables for consistent usage
:root {
  --font-family-latin: 'Noto Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  --font-family-arabic: 'Noto Sans Arabic', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

// Material Design Typography Variables
:root {
  --mdc-typography-font-family: var(--font-family-latin);
  --mat-font-family: var(--font-family-latin);
}
