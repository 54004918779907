@use "node_modules/ngx-toastr/toastr.css";
@use "node_modules/ngx-spinner/animations/ball-spin.css";
@use 'fonts';
@use 'node_modules/ngx-videogular/fonts/videogular.css';

// Keyframes for error shake animation
@keyframes errorShake {
   0% { transform: translateX(0); }
   25% { transform: translateX(-8px); }
   50% { transform: translateX(8px); }
   75% { transform: translateX(-4px); }
   100% { transform: translateX(0); }
}

// Keyframes for fade in animation
@keyframes fadeIn {
   0% { 
      opacity: 0; 
      transform: translateY(-20px);
   }
   100% { 
      opacity: 1;
      transform: translateY(0);
   }
}

html,
body {
   margin: 0;
   padding: 0;
   font-family: 'Noto Sans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

.spacing {
   flex: 1 1 auto;
}

.full-width {
   width: 100%;
}

.flex-center {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 1rem;
}

.space-around {
   align-content: space-around;
}

.row {
   display: flex;
   flex-direction: row;
}

.col {
   flex: 1;
   margin-right: 20px;
   margin-left: 20px;
}

.error-message {
   display: flex;
   align-items: center;
   gap: 0.5em;
   padding: 1em;
   background-color: #ffebee;
   border-radius: 4px;
   margin: 0.5em 0;
   border-left: 4px solid #f44336;
   width: -webkit-fill-available;
   &.show {
      animation: fadeIn 0.3s ease-out forwards, errorShake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) 0.3s both;
   }

   mat-error {
      padding-top: 7px;
      color: #f44336;
      font-weight: 500;
   }
   
   mat-icon {
      color: #f44336;
   }

   span {
      font-size: 0.9em;
      color: #f44336;
      font-weight: 500;
   }
}

mat-form-field {
   padding-bottom: 12px;
}

// For Arabic text
[lang="ar"] {
    font-family: 'Noto Sans Arabic', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    direction: rtl;
}

.loading-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   min-height: 200px;
   gap: 16px;
}