// 📂 styles/theme.scss
@use "@angular/material" as mat;
@use "sass:map";
@use "variables";
@use "fonts";

// Include Material Core Styles
@include mat.core();

// Define Typography Config
$custom-typography-config: mat.m2-define-typography-config(
  $font-family: var(--font-family-latin),
  $headline-1: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
  $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
  $subtitle-2: mat.m2-define-typography-level(15px, 24px, 400),
  $body-1: mat.m2-define-typography-level(14px, 20px, 400),
  $body-2: mat.m2-define-typography-level(14px, 24px, 500),
  $caption: mat.m2-define-typography-level(12px, 20px, 400),
  $button: mat.m2-define-typography-level(14px, 14px, 500),
  $overline: mat.m2-define-typography-level(10px, 20px, 400, $letter-spacing: 1.5px)
);

// Define Angular Material Theme
$masjids-ui-primary: mat.m2-define-palette(variables.$masjids-info-yellow-palette, 600);
$masjids-ui-accent: mat.m2-define-palette(variables.$masjids-info-blue-palette, 700);
$masjids-ui-warn: mat.m2-define-palette(variables.$masjids-info-red-palette, 500);

// Define Light Theme
$masjids-ui-theme: mat.m2-define-light-theme((
  color: (
    primary: $masjids-ui-primary,
    accent: $masjids-ui-accent,
    warn: $masjids-ui-warn
  ),
  typography: $custom-typography-config,
  density: 0,
));

// Define Dark Theme
$masjids-ui-theme-dark: mat.m2-define-dark-theme((
  color: (
    primary: $masjids-ui-primary,
    accent: $masjids-ui-accent,
    warn: $masjids-ui-warn
  ),
  typography: $custom-typography-config
));

// Apply Theme
@include mat.all-component-themes($masjids-ui-theme);

// Dark Mode Support
.theme-dark {
  @include mat.all-component-colors($masjids-ui-theme-dark);
}

// RTL Support for Arabic
[dir="rtl"] {
  .mat-mdc-form-field, 
  .mat-mdc-input-element,
  .mat-mdc-select,
  .mat-mdc-option,
  .mat-mdc-button,
  .mat-mdc-card,
  .mat-mdc-dialog-container {
    font-family: var(--font-family-arabic);
  }
}
